import { Routes, Route, useLocation } from "react-router-dom";
import Main from "components/Main";
import Register from "pages/Register";
import Login from "pages/Login";
import WorkWithUs from "pages/WorkWithUs";
import Empty from "components/Empty";
import "styles/Input.css";
import HotelSearch from "pages/HotelSearch";
import Navbar from "components/Navbar";
import BrowsePoojas from "pages/BrowsePoojas";
import PoojaBooking from "pages/PoojaBooking";
import OrderSummary from "pages/OrderSummary";
import Protected from "CustomHooks/Protected";
import BrowsePackages from "pages/BrowsePackages";
import PackageDetails from "pages/PackageDetails";
import EconomyPackage from "pages/EconomyPackage";
import AboutUs from "pages/AboutUs";
import MyOrders from "pages/MyOrders";
import PaymentCheck from "pages/PaymentCheck";
import BrowseTemples from "pages/BrowseTemples";
import TempleDetails from "pages/TempleDetails";
import { UsePageViews } from "Analytics/GoogleAnalytics";
import EPooja from "pages/EPooja";
import EPooja2Page from "pages/EPooja2Page";
import ReviewAndCheckOutEPooja from "pages/ReviewAndCheckOutEPooja";

import { TbBrandWhatsapp } from "react-icons/tb";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import ComingSoon from "pages/ComingSoon";
import ErrorPage from "Error/ErrorPage";

import { useEffect, useState } from "react";
function App() {
  UsePageViews();
  console.log(process.env.REACT_APP_HOST);
  
  const [showLinks, setShowWhatsApp] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/") {
      setShowWhatsApp(true);
    } else {
      const handleScroll = () => {
        if (
          location.pathname === "/" &&
          window.scrollY > window.innerHeight * 0.65
        ) {
          setShowWhatsApp(true);
        } else {
          setShowWhatsApp(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Empty />}></Route>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />}></Route>
        <Route path="/workWithUs" element={<WorkWithUs />}></Route>
        {/* <Route path="/hotels" element={<HotelSearch />}></Route> */}
        <Route path="/browse-poojas" element={<BrowsePoojas />}></Route>
        <Route path="/e-pooja" element={<EPooja />}></Route>
        <Route path="/e-pooja/:title" element={<EPooja2Page />}></Route>
        <Route
          path="/e-pooja/checkOut/:title"
          element={<ReviewAndCheckOutEPooja />}
        ></Route>

        <Route path="/pooja/:poojaName" element={<PoojaBooking />}></Route>

        <Route path="/browse-packages" element={<BrowsePackages />}></Route>
        <Route path="/browse-temples" element={<BrowseTemples />}></Route>
        <Route path="/temples/:templename" element={<TempleDetails />}></Route>
        <Route path="/comingSoon" element={<ComingSoon />}></Route>
        <Route path="*" element={<ErrorPage />}></Route>

        <Route
          path="/order-summary"
          element={
            <Protected>
              <OrderSummary />
            </Protected>
          }
        ></Route>

        <Route
          path="/packages/premium-package"
          element={<PackageDetails />}
        ></Route>
        <Route
          path="/packages/economy-package"
          element={<EconomyPackage />}
        ></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/my-orders" element={<MyOrders />}></Route>
        <Route path="/payment-check" element={<PaymentCheck />}></Route>
      </Routes>
      {showLinks && (
        <div className="fixed left-0 top-[30%] h-[180px] w-[30px] bg-white shadow-xl z-20 px-6 rounded-r-lg">
          <ul className="h-full w-full flex flex-col justify-around items-center">
            <li>
              <a
                href="https://www.instagram.com/avadhoot_app/?hl=en"
                target="_blank"
                className="md:text-[25px] text-[16px] hover:shadow-2xl text-red-400 "
              >
                <FaInstagram />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCTyk0Vi5T3x_NfxtRe213iQ"
                target="_blank"
                className="md:text-[25px] text-[16px] hover:shadow-2xl text-[red] "
              >
                <FaYoutube />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61564526307832"
                target="_blank"
                className="md:text-[25px] text-[16px] hover:shadow-2xl text-blue-500 "
              >
                <FaFacebookF />
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="fixed z-20 h-auto w-auto bottom-[40px] right-10 rounded-full py-2 px-2 flex justify-center items-center cursor-pointer text-green-500 hover:bg-green-600 hover:text-white hover:scale-[1.1] overflow-hidden bg-gray-200 transition-all duration-300">
        <a
          href="https://api.whatsapp.com/send?phone=919201968470"
          target="_blank"
          className="md:text-[40px] text-[20px]h-full w-full "
        >
          <TbBrandWhatsapp />
        </a>
      </div>
    </>
  );
}

export default App;
