import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineTempleHindu } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { FaAngleRight } from "react-icons/fa6";
import Footer from "components/Footer";
import { TbBrandWhatsapp } from "react-icons/tb";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1324 },
    items: 4 ,
    slidesToSlide: 2
  },
  desktop: {
    breakpoint: { max: 1324, min: 1000 },
    items: 3,
    slidesToSlide: 2
  },
  tablet: {
    breakpoint: { max: 1000, min: 664 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 664, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};



function EPooja2Page() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const location = useLocation();
  const receivedData = location.state || {};
  const { title, short_desc, place, date } = receivedData;
  const [activeIndex, setActiveIndex] = useState(2); // Start with the middle card active
  const [activeList, setActiveList] = useState(null);
  const [faqList, setFaqList] = useState(null);
  const [readMorePooja, setReadMorePooja] = useState(false);
  const [readMoreTemple, setReadMoreTemple] = useState(false);
  const navigate = useNavigate();
   
  const aboutPooja =
    "Do you face recurring problems in your life? Are you always worried about something? Are there conflicts in your household? Are you facing stress because of your boss or colleagues at your workplace? Are you dealing with court cases or hospital expenses causing financial strain? If yes, then you must participate in the Mahabhairav Aapda Haran Yagya organised by Avadhoot. Lord Bhairav is considered a fierce and awakened deity. It is said that he fulfills all desires instantly and protects his devotees.";

  const images = [
    "https://images.pexels.com/photos/2462023/pexels-photo-2462023.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/3810915/pexels-photo-3810915.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/1707422/pexels-photo-1707422.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/3731615/pexels-photo-3731615.jpeg?auto=compress&cs=tinysrgb&w=600",
  ];

  const cards = [
    {
      title: "Single",
      content: "1 person",
      price: "599",
      people :1,
      img: "https://c8.alamy.com/comp/W6XM01/south-indian-man-holding-puja-thali-W6XM01.jpg",
    },
    {
      title: "Couple",
      content: "Up to 2 people",
      price: "999",
      people :2,
      img: "https://www.shutterstock.com/image-photo/smart-indian-couple-performing-sai-260nw-2248455745.jpg",
    },
    {
      title: "Family",
      content: "Up to 3 people",
      price: "1299",
      people :3,
      img: "https://c8.alamy.com/comp/D0MCWB/family-doing-diwali-pooja-D0MCWB.jpg",
    },
    {
      title: "Family + Bhog",
      content: "Up to 6 people",
      price: "1699",
      people :6,
      img: "https://www.shutterstock.com/shutterstock/photos/1805592196/display_1500/stock-photo-happy-indian-family-celebrating-ganesh-festival-or-chaturthi-welcoming-or-performing-pooja-and-1805592196.jpg",
    },
    {
      title: "Special Package",
      content: "Special content",
      price: "2199",
      people :7,
      img: "https://media.istockphoto.com/id/1768707770/photo/happy-indian-couples-with-senior-father-dancing-together-by-holding-online-shopping-delivery.webp?s=1024x1024&w=is&k=20&c=bzKoL17IPZbPAeGdtnriDxYNS5BvdbDr31o8FiYtP_s=",
    },
  ];

  const items = [
    {
      title: "Ends of Troubles and worries",
      content:
        "To put an end to troubles and worries, the worship of Lord Bhairav is considered the most effective. Through the Mahabhairav Aapdaa Haran Yagya, anxieties and problems vanish, and you receive all kinds of happiness",
    },
    {
      title: "Self Confidence and Courage",
      content:
        "Worshipping Lord Bhairav grants self-confidence and courage. If you are troubled by unknown fears or your career or business has hit a roadblock, the Mahabhairav Puja will restart your progress again. ",
    },
    {
      title: "Strength, Wisdom, Knowledge and Respect",
      content:
        "By the grace of Lord Bhairav, you attain strength, wisdom, knowledge, and respect. Your social status improves, and delays in your endeavors come to an end. Lord Bhairav blesses you with everlasting contentment. ",
    },
    {
      title: "Pacification of Rahu and Saturm",
      content:
        "Through Lord Bhairav's grace, Rahu and Saturn get pacified in your horoscope. If you are going through the Mahadasha of Rahu, Saturn, or Ketu in your birth chart then, participating in Lord Bhairav puja will benefit you. ",
    },
  ];

  const faqs = [
    {
      title: "What does Avadhoot do Apart from pooja booking?",
      content:
        "Avadhoot is an online temple and astrology app. Through this app you can help in cow service, food donation and renovation of temples. You can also get information related to horoscopes, tarot, numerology and vastu by talking to an astrologer on the app.",
    },
    {
      title: "What Should you do on the day of pooja?",
      content:
        "On the day of pooja, observe celibacy fast and meditate on the presiding deity. Watch the entire ritual as the puja goes live on Avadhoot APP. Whose notification is sent by us to you before the pooja. ",
    },
    {
      title: "i don't know my Gotra, what to do?",
      content:
        "If you do not know your Gotra then you can write Kashyap Gotra while filling the form. (Or) You can contact our customer care number +91 xxxxxxx843. ",
    },
    {
      title: "Why only Avadhoot for online Pooja booking?",
      content:
        "Avadhoot is a reliable online platform through which you can book puja in the famous temples of the country. The puja booked by you is performed by the priests in the auspicious time. After the puja, they deliver the offerings of the puja within the specified time. ",
    },
    {
      title: "What the process after online pooja booking?",
      content:
        "After successful puja booking, the Avadhoot team will contact you within 24 hours to take your details (your name, Gotra, etc.) for the puja. You can also get other information related to puja from the team. ",
    },
    {
      title: "What is the difference between offline pooja and online pooja?",
      content:
        "There is no special difference between offline and online worship. In both types of puja, the priest performs the ritual by chanting your name and Gotra. In offline puja, you perform the rituals at your home whereas in online puja, you get all the facilities sitting at home. ",
    },
    {
      title: "Will I get get a recording of this pooja?",
      content: "Yes, Devotees will get a recorded video of the Puja. ",
    },
  ];

  const dummyReviews = [
    {
      id: 1,
      name: "Alice",
      img : "https://images.pexels.com/photos/27642667/pexels-photo-27642667/free-photo-of-a-man-standing-in-front-of-a-forest.png?auto=compress&cs=tinysrgb&w=600",
      review: "Great product! Really loved it.",
      rating: 5,
    },
    {
      id: 2,
      name: "Bob",
      img : "https://images.pexels.com/photos/27642667/pexels-photo-27642667/free-photo-of-a-man-standing-in-front-of-a-forest.png?auto=compress&cs=tinysrgb&w=600",
      review: "Not bad, but could be improved.",
      rating: 3,
    },
    {
      id: 3,
      name: "Charlie",
      img : "https://images.pexels.com/photos/27642667/pexels-photo-27642667/free-photo-of-a-man-standing-in-front-of-a-forest.png?auto=compress&cs=tinysrgb&w=600",
      review: "Excellent service and quality!",
      rating: 4,
    },
    {
      id: 4,
      name: "Diana",
      img : "https://images.pexels.com/photos/27642667/pexels-photo-27642667/free-photo-of-a-man-standing-in-front-of-a-forest.png?auto=compress&cs=tinysrgb&w=600",
      review: "Not satisfied with the customer support.",
      rating: 2,
    },
    {
      id: 5,
      name: "Edward",
      img : "https://images.pexels.com/photos/27642667/pexels-photo-27642667/free-photo-of-a-man-standing-in-front-of-a-forest.png?auto=compress&cs=tinysrgb&w=600",
      img : "https://images.pexels.com/photos/27642667/pexels-photo-27642667/free-photo-of-a-man-standing-in-front-of-a-forest.png?auto=compress&cs=tinysrgb&w=600",
      review: "Worth the price. Highly recommend.",
      rating: 5,
    },
    {
      id: 6,
      name: "Fiona",
      img : "https://images.pexels.com/photos/27642667/pexels-photo-27642667/free-photo-of-a-man-standing-in-front-of-a-forest.png?auto=compress&cs=tinysrgb&w=600",
      review: "The product didn’t meet my expectations.",
      rating: 3,
    },
    {
      id: 7,
      name: "George",
      img : "https://images.pexels.com/photos/27642667/pexels-photo-27642667/free-photo-of-a-man-standing-in-front-of-a-forest.png?auto=compress&cs=tinysrgb&w=600",
      review: "Amazing experience. Will buy again!",
      rating: 4,
    },
    {
      id: 8,
      name: "Hannah",
      img : "https://images.pexels.com/photos/27642667/pexels-photo-27642667/free-photo-of-a-man-standing-in-front-of-a-forest.png?auto=compress&cs=tinysrgb&w=600",
      review: "Good quality but a bit expensive.",
      rating: 3,
    },
    {
      id: 9,
      name: "Ivy",
      img : "https://images.pexels.com/photos/27642667/pexels-photo-27642667/free-photo-of-a-man-standing-in-front-of-a-forest.png?auto=compress&cs=tinysrgb&w=600",
      review: "Fast delivery and great quality!",
      rating: 5,
    },
    {
      id: 10,
      name: "Jack",
      img : "https://images.pexels.com/photos/27642667/pexels-photo-27642667/free-photo-of-a-man-standing-in-front-of-a-forest.png?auto=compress&cs=tinysrgb&w=600",
      review: "Average experience, could be better.",
      rating: 2,
    },
  ];

  
  const handleReadAboutPooja = () => {
    setReadMorePooja(!readMorePooja);
  };
  const handleReadMoreTemple = () => {
    setReadMoreTemple(!readMoreTemple);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };

  const handleClick = (index) => {
    setActiveList(activeList === index ? null : index);
  };
  const handleClickFaqs = (index) => {
    setFaqList(faqList === index ? null : index);
  };

  
  const handleCardClick = (index) => {
    setActiveIndex(index);
    const selectedCard = cards[index]; 
    navigate(`/e-pooja/checkOut/${receivedData.title}`, { state: { receivedData, selectedCard } });
  };
  
  const handleBookPooja = (receivedData) => {
    const selectedCard = cards[activeIndex];
    navigate(`/e-pooja/checkOut/${receivedData.title}`, { state: { receivedData, selectedCard } });
  };

   


  return (
    <>
      <div className="flex font-[inter] flex-col items-center md:items-start justify-center space-y-4 py-4 md:flex-row md:space-y-0 md:space-x-6 md:p-8 ">
        <div className="relative w-full max-w-lg md:w-1/2">
          <img
            src={images[currentIndex]}
            alt={`Puja ${currentIndex}`}
            className="w-full h-64 object-cover md:rounded-lg md:h-80 lg:h-96"
          />
          <div className="flex mt-4 space-x-2 justify-evenly object-cover px-4">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index}`}
                onClick={() => handleImageClick(index)}
                className={`w-16 h-16 object-cover cursor-pointer rounded-lg ${
                  index === currentIndex
                    ? "border-2 border-orange-400"
                    : "border"
                }`}
              />
            ))}
          </div>
        </div>
        <div className="md:w-1/2 w-[100%] md:text-left h-64  md:h-80 lg:h-96 py-4 px-2 flex flex-col justify-evenly">
          <h2 className="text-2xl font-semibold md:text-3xl">{title}</h2>
          <p className="text-sm text-gray-500 md:text-lg"> {short_desc}</p>
          <p className="text-sm text-gray-500 md:text-lg flex gap-2 items-center">
            <MdOutlineTempleHindu />
            {place}
          </p>
          <p className="text-sm text-gray-500 md:text-lg flex gap-2 items-center">
            <SlCalender />
            {date}
          </p>
          <p className="font-semibold text-sm ">
            1243 peoples already book this pooja
          </p>
          <p className="cursor-pointer flex gap-2 text-[12px] items-center">
            <TbBrandWhatsapp size={24} className="text-green-600" /> share{" "}
          </p>
          <button onClick={()=>handleBookPooja(receivedData)}  className="px-4 py-2 mt-2 border border-orange-400 rounded-lg bg-orange-400 text-white transition-all hover:bg-orange-500 md:px-6 md:py-3">
            Book Pooja
          </button>
        </div>
      </div>
      {/* packages */}
      <div className="packages font-[inter] w-full py-2 px-4 md:px-6">
        <h3 className="text-lg md:text-2xl mb-2">Pooja Packages</h3>
        <p className="mt-2 text-[#60606ae6]">
          <span className="font-semibold">Special Offer</span>: Enjoy
          complimentary Prasad with every package, plus receive a discount card
          for your next pooja booking.
        </p>
        <div  className="relative flex flex-col md:flex-row justify-center items-center min-h-[60vh] w-full">
          {cards.map((card, index) => {
            const isActive = index === activeIndex;
            return (
              <div
                key={index}
                onClick={() => handleCardClick(index)}
                onMouseEnter={() => setActiveIndex(index)}
                className={`flex flex-col items-center rounded-lg overflow-hidden m-4 transition-all duration-500 ease-in-out ${
                  isActive
                    ? "scale-100 opacity-100 z-10"
                    : "scale-90 opacity-70"
                } bg-white shadow-lg cursor-pointer`}
                style={{
                  height: isActive ? "20rem" : "14rem",
                  width: isActive ? "230px" : "150px",
                  boxShadow: isActive ? "0 0 5px 0" : "0 0 3px 0",
                }}
              >
                <img
                  src={card.img}
                  alt=""
                  className="object-cover h-full w-full"
                />
                <div
                  className={`absolute bottom-0 left-0 w-full bg-gradient-to-r from-orange-300 to-orange-500 text-white text-center transition-all duration-500 ease-in-out`}
                >
                  <h2 className="text-lg font-bold mb-0">{card.title}</h2>
                  <p>₹ {card.price}</p>
                  <p>{card.content}</p>
                </div>
              </div>
            );
          })}
        </div>

        {/* Pooja Details */}
        <div className="poojaDetails mt-6 w-[100%]">
          <h3 className="md:text-2xl text-lg mb-8  border-b">Pooja Details</h3>
          <h3 className="md:text-xl text-[12px] mt-2 mb-4 text-orange-500 w-[200px] border-b-2 border-orange-500">
            Benefits of Pooja
          </h3>
          <div className="mx-auto w-[100%] ">
            {items.map((item, index) => (
              <div
                key={index}
                className={`mb-4 border-b w-[100%] border-gray-300 `}
              >
                <button
                  onClick={() => handleClick(index)}
                  className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
                >
                  <span
                    className={`text-lg md:px-4 px-2 font-semibold ${
                      activeList === index
                        ? "border-l-4 rounded-xl border-orange-500 text-orange-400"
                        : "border-l border-orange-500"
                    } transition-all duration-300 ease-in-out`}
                  >
                    {item.title}
                  </span>
                  <span
                    className={`transition-all duration-300 ease-in-out  ${
                      activeList === index ? "rotate-90" : "rotate-0"
                    } }`}
                  >
                    <FaAngleRight />
                  </span>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-500 ${
                    activeList === index ? "max-h-96" : "max-h-0"
                  }`}
                >
                  <div className="py-2 px-2 md:text-[14px] text-[#4a4a56c6]">
                    {item.content}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* About Pooja */}
          <div className="benefits mt-10 h-full text-black">
            <h3 className="md:text-xl text-[12px] mt-2 mb-4 text-orange-500 w-[150px] border-b-2 border-orange-500">
              About Pooja
            </h3>
            <div className="flex md:justify-start justify-center items-center md:flex-row flex-col gap-4 w-[100%] ">
              <img
                className="object-cover h-[16rem] md:w-[30%] w-[80%] rounded-lg "
                src={images[0]}
                alt="About pooja image"
              />
              <p className="text-[black] transition-all duration-200 ease-in-out h-[100%] md:w-[60%] w-[90%]">
                {readMorePooja ? aboutPooja : aboutPooja.slice(0, 300)}
                <span
                  onClick={handleReadAboutPooja}
                  className="underline border-b cursor-pointer text-blue-400"
                >
                  {readMorePooja ? " read less" : " ...read more"}
                </span>
              </p>
            </div>
          </div>

          {/* About temple */}

          <div className="benefits mt-10 h-full text-black">
            <h3 className="md:text-xl text-[12px] mt-2 mb-4 text-orange-500 w-[150px] border-b-2 border-orange-500">
              About temple
            </h3>
            <div className="flex md:justify-start justify-center items-center md:flex-row flex-col gap-4 w-[100%] ">
              <img
                className="object-cover h-[16rem] md:w-[30%] w-[80%] rounded-lg "
                src={images[0]}
                alt="About pooja image"
              />
              <p className="text-[black] transition-all duration-200 ease-in-out h-[100%] md:w-[60%] w-[90%]">
                {" "}
                {readMoreTemple ? aboutPooja : aboutPooja.slice(0, 300)}
                <span
                  onClick={handleReadMoreTemple}
                  className="underline border-b cursor-pointer text-blue-400"
                >
                  {readMoreTemple ? " read less" : " ...read more"}
                </span>
              </p>
            </div>
          </div>
        </div>

        {/* Faqs */}
        <div className="benefits mt-10 h-full text-black ">
          <h3 className="md:text-2xl text-[14px] mt-2 mb-4   font-bold">
            FAQs
          </h3>
          <div className="mx-auto w-[100%] ">
            {faqs.map((item, index) => (
              <div
                key={index}
                className={`mb-4 border-b w-[100%] border-gray-300 `}
              >
                <button
                  onClick={() => handleClickFaqs(index)}
                  className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
                >
                  <span
                    className={`text-lg md:px-4 px-2 font-semibold ${
                      faqList === index
                        ? "border-l-4 rounded-xl border-orange-500 text-orange-400"
                        : "border-l border-orange-500"
                    } transition-all duration-300 ease-in-out`}
                  >
                    {item.title}
                  </span>
                  <span
                    className={`transition-all duration-300 ease-in-out  ${
                      faqList === index ? "rotate-90" : "rotate-0"
                    } }`}
                  >
                    <FaAngleRight />
                  </span>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-500 ${
                    faqList === index ? "max-h-96" : "max-h-0"
                  }`}
                >
                  <div className="py-2 px-2 md:text-[14px] text-[#4a4a56c6]">
                    {item.content}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="costumerReviews py-6 min-h-[40vh] flex justify-center flex-col">
       <h3 className="font-bold text-3xl md: ml-4">Customer Reviews</h3>

      <Carousel 
     transitionDuration={500}
     infinite={true}
     autoPlaySpeed={3000}
     keyBoardControl={true}
     autoPlay
    className=" h-[100%] py-8"
    responsive={responsive}
     >
       {dummyReviews.map((review) => (
              <div
                key={review.id}
                className="flex-shrink-0 ml-3 mr-3  max-w-md p-4 bg-white shadow-xl rounded-lg mx-2"
              >
                <div className="flex gap-2 items-end ">
                 <img src={review.img} alt={review.name} className="rounded-full h-[2rem] w-[2rem] gap-2 object-cover"/>
                <h3 className="text-[14px] font-semibold mb-2">{review.name}</h3>
                
                </div>
                <p className="text-sm mb-2">{review.review}</p>
                <div className="flex items-center">
                  {Array.from({ length: review.rating }).map((_, index) => (
                    <svg
                      key={index}
                      className="w-4 h-4 text-yellow-500 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2l2.39 4.81 5.33.77-3.87 3.77.91 5.3-4.77-2.51-4.77 2.51.91-5.3L4.28 8.58 9.61 7.81 12 2z" />
                    </svg>
                  ))}
                </div>
              </div>
            ))}
          </Carousel> 
      </div>
      <Footer />
    </>
  );
}

export default EPooja2Page;
