export const HOST = process.env.NODE_ENV === "development" ? 'http://localhost:3007' : 'https://us-central1-avadhoot-web-hosting.cloudfunctions.net/webApp'
const AUTH_ROUTE = `${HOST}/api/auth`;
const PAYMENT_ROUTE = `${HOST}/api/payment`
const APP_ROUTE = `${HOST}/api/application`
const MAIL_ROUTE = `${HOST}/api/mail`

export const GOOGLE_SIGN_IN_ROUTE = `${AUTH_ROUTE}/google/signin`

export const SIGN_IN_ROUTE = `${AUTH_ROUTE}/SignIn`
export const CHECK_USER_ROUTE = `${AUTH_ROUTE}/check-user`
export const REGISTER_NEW_USER = `${AUTH_ROUTE}/SignUp`
export const GET_OTP_ROUTE = `${AUTH_ROUTE}/get-otp`
export const VERIFY_OTP_AND_LOGIN = `${AUTH_ROUTE}/verify-and-login`

export const RAZORPAY_ORDER = `${PAYMENT_ROUTE}/create/orderId`
export const PHONEPE_ROUTE = `${PAYMENT_ROUTE}/phonepe/initiatePayment`
export const CHECK_PAYMENT_STATUS_PHONEPE = `${PAYMENT_ROUTE}/phonepe/checkStatus`

export const GET_ALL_ORDERS = `${APP_ROUTE}/getAllOrders`;
export const CREATE_ORDER = `${APP_ROUTE}/createOrder`;
export const SEND_QUERY_MAIL = `${MAIL_ROUTE}/query`;
