import DurationTag from "components/Tags/DurationTag";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import ChooseVehicle from "components/Package/ChooseVehicle";
import { useStateProvider } from "context/StateContext";
import Dropdown from "components/Package/Dropdown";
import { reducerCases } from "context/constants";
import { PiBowlFoodLight } from "react-icons/pi";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RoomsAndGuests from "components/Package/RoomsAndGuests";
import prop1 from "assets/images/prop1.avif";
import prop2 from "assets/images/prop2.webp";
import prop3 from "assets/images/prop3.jpeg";

import Gallery from "components/Package/Gallery";
import dayjs from "dayjs";
import { getFullDate, getFullDatePackage } from "utils/HelperFuncs";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlinePlace } from "react-icons/md";
import PickupRadio from "components/PickupRadio";
import { FaWhatsapp } from "react-icons/fa";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";

const PackageDetails = () => {
  const navigate = useNavigate();
  const today = getFullDatePackage();
  const [{ premiumPackage, user }, dispatch] = useStateProvider();
  const [summary, setsummary] = useState(false);
  const [date, setdate] = useState(today);
  const [highlightMessage, setHighlightMessage] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  const handlePayment = async () => {
    trackButtonClicks(
      "Premium_package_payment_btn",
      "custom_event",
      "To payment premium package"
    );
    const { $y: year, $M: month, $D: day } = date;
    dispatch({
      type: reducerCases.SET_PREMIUM_PACKAGE,
      premiumPackage: {
        ...premiumPackage,
        date: `${day}-${month}-${year}`,
      },
    });
    sessionStorage.setItem(
      "currOrder",
      JSON.stringify({
        title: "Premium Package",
        category: "Package",
        people: premiumPackage.guests,
        date: `${day}-${month}-${year}`,
        subTotal: parseInt(premiumPackage.price),
        orderObj: premiumPackage,
      })
    );
    dispatch({
      type: reducerCases.SET_CURRENT_ORDER,
      order: {
        title: "Premium Package",
        category: "Package",
        people: premiumPackage.guests,
        date: `${day}-${month}-${year}`,
        subTotal: parseInt(premiumPackage.price),
        orderObj: premiumPackage,
      },
    });

    navigate("/order-summary");
  };
  const handleApply = (guests, rooms) => {
    if (parseInt(guests) > 4) {
      setHighlightMessage(true);
    } else {
      setHighlightMessage(false);
    }
    let xprice;
    if(guests === 3){
      xprice = 15500
    }else if(guests === 2){
      xprice = 13200
    }else if(guests === 1){
      xprice = 11200
    }else if(guests>3){
      if(parseInt(guests)%3===1){
        xprice = parseInt(parseInt(guests)/3) * 15500 + 7500
      }else if(parseInt(guests)%3===2){
        xprice = parseInt(parseInt(guests)/3) * 15500 + 12000
      }else{
        xprice = parseInt(parseInt(guests)/3) * 15500
      }
      // xprice = (parseInt(guests)-3)*2500 + 13500
    }
    if(premiumPackage.airportpick){
      xprice+=premiumPackage.pickupPrice
    }
    let {dayOne, dayTwo}=premiumPackage;
    if (dayOne.vehicle === 'muv'){
      xprice+=premiumPackage.muvPrice[0]
    }
    if(dayTwo.vehicle === 'muv'){
      xprice+=premiumPackage.muvPrice[1]
    }
    dispatch({
      type: reducerCases.SET_PREMIUM_PACKAGE,
      premiumPackage: {
        ...premiumPackage,
        guests,
        rooms,
        price: xprice,
      },
    });
  };
  const handleDateChange = (e) => {
    let bookingDate = dayjs(`${e.$y}-${e.$M + 1}-${e.$D}`);
    setdate(bookingDate);
    dispatch({
      type: reducerCases.SET_PACKAGE_DATE,
      setDate: {
        packageName: "premiumPackage",
        date: `${e.$y}-${e.$M + 1}-${e.$D}`,
      },
    });
  };
  const handleRemoveUjjainDarshan = () => {
    let c = window.confirm(
      "This activity will be removed from package, Are you sure you want to continue?"
    );
    if (c) {
      dispatch({
        type: reducerCases.SET_PREMIUM_PACKAGE,
        premiumPackage: {
          ...premiumPackage,
          price: premiumPackage.price - 1500,
          dayOne: {
            ...premiumPackage.dayOne,
            ujjainDarshan: false,
          },
        },
      });
    }
  };
  return (
    <>
      <div className="mb-10 min-h-[100vh]">
        <div id="booking-time" className="py-6 px-5 bg-slate-50 shadow-sm">
          <div className="flex md:flex-row md:gap-x-5 flex-col gap-y-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={handleDateChange}
                minDate={today}
                value={date}
                sx={{ color: "grey", boxShadow: "10px" }}
                disablePast
                className="rounded-lg"
                label="TRAVELLING ON"
              />
            </LocalizationProvider>
            <RoomsAndGuests handleApply={handleApply} />
          </div>
        </div>
        
        <p className="leading-[20px] text-red-500 text-center md:mx-20 mx-5 py-2">
          **Note - If arrival of travellers in Ujjain is at night/day before the
          day of the package is booked, then they can contact our team for their
          stay.**
          We recommend travellers to plan their arrival in Ujjain in the morning
          before 11:30 AM on the day of booking of the package.**

        </p>
        {/* <p className="leading-[20px] text-red-500 text-center md:mx-20 mx-5 py-2">
          ** Note - 
        </p> */}

          {highlightMessage && (
            <div>

          <p className="leading-[20px] text-yellow-600 bg-yellow-100 border-l-4 border-yellow-500 text-center md:mx-20 mx-5 py-2 my-4 font-semibold">
            To get the best deals on this package for 5 or more people, specify the requirements (people, date, time,etc) to text message on 
            <a
              href="https://wa.me/message/H5LRCGI36GPIG1"
              className="text-green-600 underline mx-1 inline-flex items-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="mr-1" /> WhatsApp
            </a>
            number or call
            <span
            
              className="text-blue-600 underline mx-1"
            >
              +919201968470
              </span>
          
            so that we can give the discounted prices with excellent services.
          </p>
          </div>
        )}
        <div id="gallery" className="md:mx-20 mx-5">
          <h1 className="text-3xl font-bold mt-5 mb-1 ml-2">Premium Jyotirlinga Package</h1>
          <div className="flex flex-row gap-x-2 ml-3">
            <DurationTag width={86} data={"Customizable"} />
            <DurationTag width={46} data={"2N/3D"} />
          </div>
        </div>

        <div>
          <div className="flex flex-row md:mx-20 mx-5 mt-5 mb-2 gap-x-1 pl-2">
            <button className="rounded-full border border-gray-400 hover:border-none focus:bg-orange-400 focus:border-none focus:text-white py-1.5 px-5 text-black transition-all hover:bg-orange-400 hover:text-white text-center text-sm ">
              Iternary
            </button>
            <button className="rounded-full border border-gray-400 hover:border-none focus:bg-orange-400 focus:border-none focus:text-white py-1.5 px-5 text-black transition-all hover:bg-orange-400 hover:text-white text-center text-sm">
              Summary
            </button>
          </div>
          <div className="py-3 md:mx-20 mx-10 px-3">
            <PickupRadio pack={"premiumPackage"} price={2500} />
          </div>
          <div className="shadow-xl border rounded-lg md:mx-20 mx-5 md:px-10 px-7">
            <Accordion type="multiple" collapsible className="w-full">
              <AccordionItem value="item-1">
                <AccordionTrigger>Day 1</AccordionTrigger>
                <AccordionContent>
                  <h3 className="text-2xl font-semibold my-1">Ujjain</h3>
                  <ul className="border rounded-lg py-3">
                    <li className="px-2 py-3">
                      <div className="">
                        <Dropdown
                          packageName={"premiumPackage"}
                          incprice={1300}
                          day={"dayOne"}
                        />
                        <ChooseVehicle
                          price={1300}
                          data={
                            " Transfer to hotel | Mahakaleshwar Darshan | Ujjain darsharn & sightseeing"
                          }
                          vehicle={premiumPackage.dayOne.vehicle}
                        />
                      </div>
                    </li>
                    <li className="px-2">
                      <div className="ml-2">
                        <h1 className="text-lg font-semibold mt-2 inline-block">
                          <span className="header_span mr-1" />
                          Hotel Check-In (2⭐ or 3⭐)
                        </h1>
                        <h3 className="text-gray-500 ml-3">
                          - Our recommended Hotel near temple and with peaceful
                          environment
                        </h3>
                        <div className="md:ml-10">
                          <Gallery
                            about={"Property Photos"}
                            images={[prop2, prop3]}
                          />
                        </div>
                      </div>
                    </li>
                    <li className="px-2 py-3">
                      <div className="ml-2">
                        <h1 className="text-lg font-semibold mt-2 inline-block">
                          <span className="header_span mr-1" />
                          Mahakaleshwar VIP Darshan
                        </h1>
                        <h3 className="text-gray-500 ml-3">
                          - Private transefer from hotel to Mahakaleshwar
                          mandir.
                        </h3>
                      </div>
                    </li>
                    {premiumPackage.dayOne.ujjainDarshan && (
                      <li className=" px-2 py-3">
                        <div className="ml-2">
                          <h1 className="text-lg font-semibold mt-2">
                            <span className="header_span mr-1" />
                            Ujjain Darshan (5 temples)
                            <span>
                              <button
                                onClick={handleRemoveUjjainDarshan}
                                className="text-[16px] font-normal text-red-500 ml-5 border-none hover:underline"
                              >
                                REMOVE
                              </button>
                            </span>
                          </h1>
                          <h3 className="text-gray-500 ml-3">
                            - Visit 5 Major temples, including (Kaal Bhairava,
                            Maa Harsiddhi Temple, Mangalnath Temple, Sandinpani
                            Aashram, Bharthari Gufa and other).
                          </h3>
                        </div>
                      </li>
                    )}
                  </ul>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2">
                <AccordionTrigger>Day 2</AccordionTrigger>
                <AccordionContent>
                  <h3 className="text-2xl font-semibold my-1">
                    Ujjain to Omkareshwar
                  </h3>
                  <ul className="border rounded-lg py-3">
                    <li className="px-2 py-3">
                      <div className="">
                        <Dropdown
                          incprice={1800}
                          day={"dayTwo"}
                          packageName={"premiumPackage"}
                        />
                        <ChooseVehicle
                          price={1800}
                          data={
                            "Trip to Omkareshwar | Omkareshwar Jyotirlinga Darshan"
                          }
                          vehicle={premiumPackage.dayTwo.vehicle}
                        />
                        <div className="ml-2">
                          <h1 className="text-lg font-semibold mt-2 inline-block">
                            <PiBowlFoodLight className="inline-block mb-1 mr-1 text-xl" />
                            Complementry Breakfast included
                          </h1>
                          {/* <h3 className="text-gray-500 ml-3">
                            - Lunch at Omkareshwar and Dinner at Ujjain
                          </h3> */}
                        </div>
                        <div className="ml-2">
                          <h1 className="text-lg font-semibold mt-2 inline-block">
                            <MdOutlinePlace className="inline-block mb-1 mr-1 text-xl" />
                            Sightseeing includes Omkareshwar, Mamleshwar
                            jyotirlinga and others.
                          </h1>
                        </div>
                      </div>
                    </li>
                  </ul>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-3">
                <AccordionTrigger>Day 3</AccordionTrigger>
                <AccordionContent>
                  <h1 className="text-2xl font-semibold">
                    <span className="header_span mr-1" />
                    Check-out and Drop
                  </h1>
                  <h3 className="text-gray-500 ml-3">- Beakfast at hotel</h3>
                  <h3 className="text-gray-500 ml-3">
                    - Check-out from hotel and drop to your departure place.
                  </h3>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div id="summary"></div>
      </div>
      <div className="z-10 bg-blue-600 sticky bottom-0 py-3 px-5 flex md:flex-row gap-y-2 flex-col">
        <div>
          <h3 className=" text-white font-bold text-2xl">
            Package Price : ₹{premiumPackage.price}
          </h3>
          <p className="text-xs text-white">Excluding applicable tax</p>
        </div>
        <button
          onClick={handlePayment}
          className="ml-3 lg:ml-[70%] rounded-full border bg-white py-1.5 px-5 text-sky-600 transition-all hover:text-sky-700 hover:shadow-2xl text-center"
        >
          Proceed to Payment
        </button>
      </div>
    </>
  );
};

export default PackageDetails;
