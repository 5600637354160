import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineTempleHindu } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { TbReceiptRupee } from "react-icons/tb";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { useStateProvider } from 'context/StateContext';
import { getFullDatePackage } from 'utils/HelperFuncs';
import { reducerCases } from 'context/constants';
import { PiHandsPrayingLight } from "react-icons/pi";


function ReviewAndCheckOutEPooja() {
    const location = useLocation();
    const { receivedData, selectedCard } = location.state || {};
    const today = getFullDatePackage();
     const navigate = useNavigate();
     const [{ economyPackage, user }, dispatch] = useStateProvider();
     const [date, setdate] = useState(today);
     
     const [totalPrice, setTotalPrice] = useState(selectedCard.price);
     const [selectedAddOns, setSelectedAddOns] = useState([]);
     const [showModel , setShowModel] = useState(false);      
     const [visibleModel, setVisibleModel] = useState(false); 
     const [showSecondStep , setSecondStep] = useState(false); 
     const userLoggedIn = JSON.parse(sessionStorage.getItem("user"));
     
     
     const handleAddOn = (addOn) => {
            if (!selectedAddOns.includes(addOn.id)) {
                setSelectedAddOns([...selectedAddOns, addOn.id]);
                setTotalPrice(prevPrice => Number(prevPrice) + Number(addOn.price));
            }else{
                setSelectedAddOns(selectedAddOns.filter(id => id !== addOn.id));
                setTotalPrice(prevPrice => Number(prevPrice) - Number(addOn.price));
            }
      };


    const handlePayment = async ()=>{
        if(userLoggedIn){
            const {$y:year,$M:month ,$D:day} = date;
        const addOns = dummyAddOnData.filter(item=>{
           return selectedAddOns.includes(item.id)
        })
        sessionStorage.setItem("currOrder", JSON.stringify(
          {
            title:receivedData?.title,
            category: "ePooja",
            people:selectedCard.people,
            date:`${day}-${month}-${year}`,
            subTotal:parseInt(selectedCard.price),
            orderObj:{...receivedData, ...selectedCard},
            addOns
          }
        ))
        dispatch({
          type:reducerCases.SET_CURRENT_ORDER,
          order:{
            title:receivedData?.title,
            category: "ePooja",
            people:selectedCard.people,
            date:`${day}-${month}-${year}`,
            subTotal:parseInt(selectedCard.price),
            orderObj:{...receivedData, ...selectedCard},
            addOns
          }
        })
        navigate("/order-summary");
        }else{
            setShowModel(!showModel);
            setTimeout(()=>{
                setVisibleModel(!visibleModel);
            },10)    
        }
      }


    const dummyAddOnData = [
        {
           id: 0,
           title: "Regular Puja Prasad",
           desc: "Puja Prasad will be delivered to the doorstep of devotees after the completion of Puja.",
           price: 199,
           img: 'https://vama.app/_next/image?url=https%3A%2F%2Fstorage.googleapis.com%2Fvama-prod-assets%2F7423fedf-4436-4234-9eb6-588f20766f43.jpg&w=3840&q=100'
        },
        {
           id: 1,
           title: "Dakshina to Pujari and Brahmans",
           desc: "This contribution helps Panditji to earn his livelihood.",
           price: 599,
           img: 'https://vama.app/_next/image?url=https%3A%2F%2Fstorage.googleapis.com%2Fvama-prod-assets%2F217cc05e-003d-446c-8164-d36534614f7f.jpg&w=3840&q=100'
        },
        {
           id: 2,
           title: "Vashikaran Yantra",
           desc: "Vashikaran Yantra helps you to attract the partner of your choice.",
           price: 1199,
           img: 'https://vama.app/_next/image?url=https%3A%2F%2Fstorage.googleapis.com%2Fvama-prod-assets%2Fff4fb3f2-ef52-483b-9eb1-85039eeb631e.webp&w=3840&q=100'
        },
    ];

   
    const handleCloseModel = ()=>{
        setVisibleModel(false);
        setTimeout(()=>{
            setShowModel(false);
            setSecondStep(false) 
        },500)
    }
   
    const handleLoggedInGuest = ()=>{
          setSecondStep(!showSecondStep);
    }
    

    return (
        <div className='relative'>  
      {showModel && (
        <div 
          className={`fixed top-0 left-0 w-full h-full p-4 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-500 ease-out z-50 ${
            visibleModel ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <div
            className={`relative p-6 rounded-lg overflow-hidden shadow-lg max-w-sm w-full bg-gradient-to-b from-orange-300 to-orange-500 transition-transform duration-500 ease-out transform ${
              visibleModel ? 'scale-100' : 'scale-90'
            }`}
          >
            
            <div>
              <h3 className='text-white mt-6 font-semibold flex justify-center items-center w-full  '><PiHandsPrayingLight size={28}  className='text-center'/></h3>
              {/* <h2>Book Pooja Now</h2> */}
            </div>
           {!showSecondStep ? 
            <div className="p-4">
              <h3 className="text-center mb-3 font-semibold text-gray-600 md:text-lg">
                Please login to continue Book Pooja
              </h3>
              <div className="flex flex-col justify-center items-center gap-6">
                <div className="flex gap-2 justify-center items-center">
                  <span className="bg-white rounded-md p-2 font-semibold">+91</span>
                  <input
                    type="text"
                    maxLength={10}
                    minLength={10}
                    required
                    placeholder="Enter your mobile number"
                    className="outline-none border-none p-2 rounded font-semibold placeholder:text-gray-600 placeholder:font-light"
                  />
                </div>
                <button className="bg-orange-600 rounded w-[50%] p-2 text-white font-semibold hover:bg-orange-700">
                  Send OTP
                </button>
              </div>
              <h4 className="text-center text-white font-semibold mt-6 text-opacity-70">
                Click to continue as{' '}
                <span onClick={handleLoggedInGuest} className="underline text-white text-opacity-100 cursor-pointer">
                  guest?
                </span>
              </h4>
             </div>
            :
            
            <div className='mt-2 flex flex-col justify-center items-center gap-3 p-4'>
                  <h3 className='text-opacity-80 text-gray-600'><span className='font-semibold text-opacity-100 text-gray-600'>note</span> : one filed is required.</h3>
                  <div className='flex flex-col justify-center items-center gap-3 w-full '>
                     
                  <input type="email" placeholder='email' name="" id="" className='p-2 rounded outline-none w-[100%] font-semibold  placeholder:font-light'/>
                  <span className='font-semibold mb-[-10px] mt-[-10px]'>OR</span>
                  <input type="text" placeholder='whatsApp Number' name="" id="" className='p-2 rounded outline-none w-[100%] font-semibold placeholder:font-light' />
                  </div>
                  <div className='mt-1'>
                      <button className='px-6 py-2 bg-orange-600 hover:bg-orange-700 rounded text-white font-semibold'>Continue</button>
                  </div>
             <h4 className="text-center text-white font-semibold mt-3 text-opacity-70">
                continue with {' '}
                <span onClick={handleLoggedInGuest} className="hover:underline  text-white text-opacity-100 cursor-pointer">
                  login?
                </span>
              </h4>
                  {/* <button className='py-2 px-4 bg-' onClick={handleLoggedInGuest}>continue login now</button> */}

            </div>

            }
          
            <button
              onClick={handleCloseModel}
              className="absolute top-[-5px] right-0 bg-orange-600 py-1 px-3 text-white text-2xl"
            >
              x
            </button>
          </div>
        </div>
      )}

        <div className='font-[inter] py-6 h-full w-[100%]'>
            <h2 className='text-center text-2xl mb-4'>Review and Checkout</h2>
            <div className='flex flex-wrap justify-around py-4'>
                <div className="h-[100%] gap-4 md:w-[55%] w-[90%]">
                    <div className="mainCard mb-4 relative border h-full rounded-lg flex md:flex-row flex-col gap-4 py-4 px-4 w-[100%]">
                        <img src={receivedData.img} alt="" className='md:h-[30vh] h-[40vh] w-[100%] max-h-[230px] rounded-xl md:w-[12rem] shadow-lg object-cover' />
                        <div>
                            <h3 className='text-xl'>{receivedData.title}</h3>
                            <p className='flex gap-2 items-center'><MdOutlineTempleHindu /> {receivedData.place}</p>
                            <p className='flex gap-2 items-center'><SlCalender /> {receivedData.date}</p>
                            <p>Package: {selectedCard.title}</p>
                            <p className='flex items-center gap-2'>Sub-Total: <TbReceiptRupee /> {selectedCard.price}</p>
                        </div>
                        <div className='bg-orange-500 bottom-0 right-0 md:text-[14px] text-[10px] md:p-2 px-2 rounded-l-sm absolute text-white'>
                            Avadhoot Pvt. Ltd. Company
                        </div>
                    </div>

                    <div className=" px-4 py-4 w-[100%] gap-4">
                        <h3 className='text-xl font-semibold mb-4'>Add On</h3>
                        <div className='mb-2'>
                            {dummyAddOnData.map((item) => (
                              <div key={item.id} className='border  w-full mb-2 flex flex-col justify-center items-center'>
                              <div className='flex md:flex-row  flex-col items-end justify-between rounded-lg py-4 px-4 w-[100%]'>
                                    <div className="flex items-center md:flex-row flex-col gap-4 h-full w-[100%]">
                                        <img src={item.img} alt={item.title} className='md:h-[100%] md:w-[10rem] w-[100%] h-[200px] object-cover rounded-lg' />
                                        <div className='items-left w-full flex flex-col gap-2'>
                                            <span className='font-semibold text-lg mt-2'>{item.title}</span>
                                            <span>{item.desc}</span>
                                            <span className='flex items-center gap-2'><FaIndianRupeeSign /> {item.price}</span>
                                            <div className='flex justify-center items-center md:justify-end'>
                                            <div onClick={() => handleAddOn(item)} className="bg-orange-500 hover:bg-orange-600 cursor-pointer duration-300 transition-all text-white px-8 py-2 rounded-lg md:w-[50%] min-w-[180px] w-[80%]">
                                               <button className='w-[100%] h-[100%]' >{selectedAddOns.includes(item.id) ? "Remove" : "Add"}</button>
                                           </div>
                                        </div>
                                        </div>
                            
                                    </div>
                                   
                                </div>
                             {/* <div onClick={() => handleAddOn(item)} className="btn bg-orange-500 hover:bg-orange-600 cursor-pointer duration-300 transition-all text-white px-8 py-2 rounded-lg max-w-[400px] w-[70%]">
                                    <button>{selectedAddOns.includes(item.id) ? "Remove" : "Add"}</button>
                                    </div> */}
                            </div>

                            ))}
                        </div>
                    </div>
                </div>

                <div className="right border rounded-lg p-4 md:w-[30%] w-[90%] h-full">
                    <h3 className='text-xl font-semibold mb-4'>Payment Summary</h3>
                    <div className='flex justify-between mb-4'>
                        <span>Puja:</span>
                        <span className='flex items-center gap-2'><FaIndianRupeeSign /> {selectedCard.price}</span>
                    </div>
                    {selectedAddOns.length > 0 && (
                        <div className='flex flex-col mb-4'>
                            {dummyAddOnData.filter(item => selectedAddOns.includes(item.id)).map(addOn => (
                                <div key={addOn.id} className='flex justify-between'>
                                    <span>{addOn.title.slice(0,23)+ "..."}</span>
                                    <span className='flex items-center gap-2'><FaIndianRupeeSign /> {addOn.price}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className='flex justify-between items-center'>
                        <span>Total:</span>
                        <span className='flex items-center gap-2'><FaIndianRupeeSign /> {totalPrice}</span>
                    </div>
                    <button onClick={handlePayment}  className="bg-orange-500 text-white w-full py-2 mt-4 rounded-lg flex justify-center items-center">Make Payment (<FaIndianRupeeSign /> {totalPrice})</button>
                </div>
            </div>
        </div> 
</div> );
}

export default ReviewAndCheckOutEPooja;
