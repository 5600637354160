import React, { useState, useRef, useEffect, useCallback } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import image1 from "assets/images/Carousel/c1.jpg";
import image2 from "assets/images/Carousel/c2.jpg";
import image3 from "assets/images/Carousel/c3.jpg";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
const slidesData = [image1, image2, image3];
const s_data = [
  {
    img: image1,
    html: `
      <h1 className='absolute top-[8%] md:left-[5%] left-[3%] text-white md:text-6xl text-3xl font-semibold'>
        Explore Ujjain, <br /> The city of Temples
      </h1>
      <div className='absolute md:top-[60%] top-[70%] md:left-[5%] left-[3%]'>
        <p className='md:text-4xl text-xl inline-block font-semibold text-white bg-black p-2 rounded-lg'>
          With our Jyotirling Tirath Packages
        </p>
        <p className='mt-1 text-xl md:text-2xl font-semibold text-gray-800'>
          Starting at just ₹6999/- for 2 Adults
        </p>
        <button className='carousel_btn mt-3 text-2xl font-semibold'>
          <a href="/browse-packages">Buy Now</a>
        </button>
      </div>
    `,
  },
  {
    img: image1,
    html: `
      <h1 className='absolute md:top-[12%] top-[6%] left-[4%] md:text-5xl text-3xl font-semibold'>
        Kaal Sarp Dosh? Pitra Dosh? <br /> We got you covered
      </h1>
      <div className='absolute md:top-[50%] top-[70%] left-[4%]'>
        <p className='text-2xl md:text-3xl md:text-black font-semibold backdrop:blur mb-3 rounded-lg'>
          Book Pujas in Ujjain, <br /> Performed by highly experienced Pandit Ji's
        </p>
        <button className='carousel_btn text-xl font-semibold'>
          <a href="/browse-poojas">Book Pujas</a>
        </button>
      </div>
    `,
  },
  {
    img: image1,
    html: `
      <h1 className='absolute top-[5%] md:top-[7%] right-[3%] text-right text-white md:text-5xl text-2xl font-semibold'>
          Have blessings of <br/> Jyotirlinga of Madhya Pradesh
        </h1>
        <div className='absolute top-[70%] left-[3%] mx-3 md:mx-auto'>
          <p className='inline-block md:text-3xl text-xl font-semibold bg-black p-1 text-white backdrop:blur rounded-lg'>
          With our Premium
          </p>
          <p className='md:text-2xl text-xl font-semibold bg-black p-1 text-white backdrop:blur rounded-lg'>
          Mahakaleshwar and Omkareshwar Tirath Package
          </p>
          <button className='carousel_btn mt-3 text-2xl font-semibold'>
            <a href="packages/premium-package">Buy Now</a>
          </button>
        </div>
    `,
  },
];

const CarouselSlider1 = ({ slideDur = 4000 }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const slideRef = useRef(null);
  const navigate = useNavigate()

  const handleNext = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => prevIndex + 1);
  }, [isTransitioning]);

  const handlePrev = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => prevIndex - 1);
  }, [isTransitioning]);

  useEffect(() => {
    const interval = setInterval(handleNext, slideDur);
    return () => clearInterval(interval);
  }, [handleNext, slideDur]);

  useEffect(() => {
    const handleTransitionEnd = () => {
      setIsTransitioning(false);
      if (currentIndex === 0) {
        if (slideRef.current) {
          slideRef.current.style.transition = "none";
        }
        setCurrentIndex(slidesData.length);
      } else if (currentIndex === slidesData.length + 1) {
        if (slideRef.current) {
          slideRef.current.style.transition = "none";
        }
        setCurrentIndex(1);
      }
    };

    if (slideRef.current) {
      slideRef.current.addEventListener("transitionend", handleTransitionEnd);
    }

    return () => {
      if (slideRef.current) {
        slideRef.current.removeEventListener(
          "transitionend",
          handleTransitionEnd
        );
      }
    };
  }, [currentIndex]);

  useEffect(() => {
    if (isTransitioning && slideRef.current) {
      slideRef.current.style.transition = "transform 1s ease-in-out";
    }
    if (slideRef.current) {
      slideRef.current.style.transform = `translateX(-${currentIndex * 100}%)`;
    }
  }, [currentIndex, isTransitioning]);

  const validIndex =
    currentIndex === 0
      ? slidesData.length
      : currentIndex > slidesData.length
      ? 1
      : currentIndex;

  return (
    <div className="relative overflow-hidden w-[100%] h-[80vh] flex justify-center items-center ">
      <div className="relative w-[100%] h-[100%] overflow-hidden ">
        <div
          className="flex transition-all duration-700 ease-in-out h-[100%] w-[100%] "
          ref={slideRef}
        >
          <div className="min-w-[100%] min-h-[100%] flex justify-center items-center">
            <img
              src={slidesData[slidesData.length - 1]}
              alt=""
              className="h-full w-full object-cover"
            />
          </div>
          {slidesData.map((slide, index) => (
            <div
              key={index}
              className="relative min-w-[100%] min-h-[100%] flex justify-center items-center "
            >
              <img src={slide} alt="" className="h-full w-full object-cover" />

              {parse(s_data[index].html)}
            </div>
          ))}
          <div className="min-w-[100%] min-h-[100%] bg-green-400 flex justify-center items-center">
            <img
              src={slidesData[0]}
              alt=""
              className="h-full w-full object-cover"
            />
          </div>
        </div>

        <div className="absolute flex justify-between items-center top-[50%] w-[100%] px-4">
          <button
            onClick={handlePrev}
            aria-label="Previous Slide"
            className="bg-gray-800 text-white px-2 py-2 rounded-full hover:bg-gray-400 transition-colors duration-150"
          >
            <span>
              <FaChevronLeft />
            </span>
          </button>
          <button
            onClick={handleNext}
            aria-label="Next Slide"
            className="bg-gray-800 text-white px-2 py-2 rounded-full hover:bg-gray-400 transition-colors duration-150"
          >
            <span>
              <FaChevronRight />
            </span>
          </button>
        </div>

        <div className="absolute left-[46%] flex justify-center items-center gap-4 bottom-[10px]">
          {slidesData.map((_, index) => (
            <span
              key={index}
              className={`${
                index + 1 === validIndex
                  ? "p-2 bg-white"
                  : " bg-slate-200 opacity-40 p-2"
              } rounded-full inline-block cursor-pointer`}
              onClick={() => setCurrentIndex(index + 1)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselSlider1;
