import React from 'react'
import { MdOutlineTempleHindu } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import PoojaImages from "assets/images/Poojas/PoojaImages";
import poojaLogo from "assets/icons/pooja.png";
import { useNavigate } from 'react-router-dom';
import Footer from 'components/Footer';

const dummyData = [
    {
      id: 0,
      title: "Mangal Dosh Nivaran (Bhat) Puja",
      short_desc: "Alleviate Manglik Dosha for a harmonious life.",
      img : "https://images.pexels.com/photos/2462023/pexels-photo-2462023.jpeg?auto=compress&cs=tinysrgb&w=600",
      place: "Online e - pooja",
      date : "31 Aug",    
      ends : "2 days left for booking"
    },
    {
      id: 1,
      title: "Kaal Sarpa Dosha Puja",
      short_desc: "Remove Kaal Sarpa Dosha for peace and success.",
      img : "https://images.pexels.com/photos/3810915/pexels-photo-3810915.jpeg?auto=compress&cs=tinysrgb&w=600",
      place: "Online e - pooja",
      date : "3 Sep",
      ends : "3 days left for booking"    
    },
    {
      id: 2,
      title: "Pitra Dosha Puja",
      short_desc: "Pacify ancestors for a harmonious life.",
      img : "https://images.pexels.com/photos/3810915/pexels-photo-3810915.jpeg?auto=compress&cs=tinysrgb&w=600",
      place: "Online e - pooja",
      date : "6 Sep",    
      ends : "6 days left for booking"
    },
    {
      id: 3,
     title: "Rudrabhishek Puja",
     short_desc: "Invoke Lord Shiva's blessings for health and prosperity.",
     img : "https://images.pexels.com/photos/3810915/pexels-photo-3810915.jpeg?auto=compress&cs=tinysrgb&w=600",
     place: "Online e - pooja",
     date : "11 Sep",
     ends : "4 days left for booking"    
    },
    {
      id: 4,
     title: "Rudrabhishek Puja",
     short_desc: "Invoke Lord Shiva's blessings for health and prosperity.",
     img : "https://images.pexels.com/photos/3810915/pexels-photo-3810915.jpeg?auto=compress&cs=tinysrgb&w=600",
     place: "Online e - pooja",
     date : "11 Sep",
     ends : "3 days left for booking"    
    },
    {
      id: 5,
     title: "Rudrabhishek Puja",
     short_desc: "Invoke Lord Shiva's blessings for health and prosperity.",
     img : "https://images.pexels.com/photos/3810915/pexels-photo-3810915.jpeg?auto=compress&cs=tinysrgb&w=600",
     place: "Online e - pooja",
     date : "11 Sep",
     ends : "11 days left for booking"    
    },
]

function EPooja() {
   const navigate = useNavigate();
   const handleCardClick = (pooja)=>{
      if(pooja.title){
         navigate(`/e-pooja/${pooja.title}`, {state : pooja}); 
      }        
  }  

  return (<>
    <div className="grid justify-items-center">
    <h1 className="text-center p-3 font-bold text-orange-500 text-4xl mt-6">
      E - pooja
    </h1>
    <div className="grid w-full lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 justify-items-center mt-10">
      {dummyData.map((pooja, ind) => {
        return ( <div key={ind}
          onClick={() => handleCardClick(pooja)}
          className="pr-0 pl-0 w-72 my-8 px-3 shadow-xl hover:translate-y-[-5px] transition-transform rounded-lg md:w-[70%] cursor-pointer"
        >
          <div className="h-full px-2 flex flex-col justify-between">
             <div className="relative overflow-hidden w-[100%] h-full rounded-lg "> 
              <img className=" w-[100%] h-[100%] object-cover" src={PoojaImages[pooja.id] ? PoojaImages[pooja.id] : poojaLogo} />
              <span className='absolute gap-2 text-[10px] rounded-[5px] font-semibold  left-0 top-0 border border-orange-400 bg-orange-400 py-1.5 px-2 text-white transition-all hover:bg-orange-500 text-center text-sm flex items-center justify-center'>
                 {pooja.ends} 
               </span> 
              <span className='absolute gap-2 rounded-[5px] font-semibold  right-[-4px] bottom-[-5px] border border-orange-400 bg-orange-400 py-1.5 px-2 text-white transition-all hover:bg-orange-500 text-center text-sm flex items-center justify-center'>
                 <SlCalender/> {pooja.date} 
               </span> 
            </div> 
            <div className="relative">
              <div className="min-h-[100] mt-1">
                <div>
                  <h3 className="text-xl font-semibold overflow-hidden ">
                    {pooja.title.length > 25 ? pooja.title.slice(0,23)+ "..." : pooja.title}
                  </h3>
                </div>
                <div>
                  <h5 className="text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap">
                    {pooja.short_desc}
                  </h5>
                </div>
               <div>
               {pooja.place &&  
               <h5 className="text-gray-500 flex items-center mt-2 gap-2 overflow-hidden text-ellipsis whitespace-nowrap">
                  <MdOutlineTempleHindu/>
                  {pooja.place}
               </h5>
                 }
                </div>         
              </div>
              <div className="mt-2 py-3">
                <button onClick={()=>handleCardClick(pooja)} className="book_btn mb-0">
                  Book Pooja
                </button>
              </div>
            </div>
          </div>
        </div>
        );
      })}
    </div>
  </div>
  
  <Footer/>
  </>)
}

export default EPooja